exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-contact-index-tsx": () => import("./../../../src/pages/contact/index.tsx" /* webpackChunkName: "component---src-pages-contact-index-tsx" */),
  "component---src-pages-contact-thanks-tsx": () => import("./../../../src/pages/contact/thanks.tsx" /* webpackChunkName: "component---src-pages-contact-thanks-tsx" */),
  "component---src-pages-faq-index-js": () => import("./../../../src/pages/FAQ/index.js" /* webpackChunkName: "component---src-pages-faq-index-js" */),
  "component---src-pages-features-document-classification-tsx": () => import("./../../../src/pages/features/document-classification.tsx" /* webpackChunkName: "component---src-pages-features-document-classification-tsx" */),
  "component---src-pages-features-index-js": () => import("./../../../src/pages/features/index.js" /* webpackChunkName: "component---src-pages-features-index-js" */),
  "component---src-pages-features-named-entity-recognition-tsx": () => import("./../../../src/pages/features/named-entity-recognition.tsx" /* webpackChunkName: "component---src-pages-features-named-entity-recognition-tsx" */),
  "component---src-pages-features-project-management-tsx": () => import("./../../../src/pages/features/project-management.tsx" /* webpackChunkName: "component---src-pages-features-project-management-tsx" */),
  "component---src-pages-features-quality-control-tsx": () => import("./../../../src/pages/features/quality-control.tsx" /* webpackChunkName: "component---src-pages-features-quality-control-tsx" */),
  "component---src-pages-features-relationships-tsx": () => import("./../../../src/pages/features/relationships.tsx" /* webpackChunkName: "component---src-pages-features-relationships-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-individual-js": () => import("./../../../src/pages/individual.js" /* webpackChunkName: "component---src-pages-individual-js" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-pricing-index-js": () => import("./../../../src/pages/pricing/index.js" /* webpackChunkName: "component---src-pages-pricing-index-js" */),
  "component---src-pages-signup-academic-tsx": () => import("./../../../src/pages/signup/academic.tsx" /* webpackChunkName: "component---src-pages-signup-academic-tsx" */),
  "component---src-pages-signup-confirm-index-tsx": () => import("./../../../src/pages/signup/confirm/index.tsx" /* webpackChunkName: "component---src-pages-signup-confirm-index-tsx" */),
  "component---src-pages-signup-confirm-launch-tsx": () => import("./../../../src/pages/signup/confirm/launch.tsx" /* webpackChunkName: "component---src-pages-signup-confirm-launch-tsx" */),
  "component---src-pages-signup-index-tsx": () => import("./../../../src/pages/signup/index.tsx" /* webpackChunkName: "component---src-pages-signup-index-tsx" */),
  "component---src-pages-signup-pay-index-tsx": () => import("./../../../src/pages/signup/pay/index.tsx" /* webpackChunkName: "component---src-pages-signup-pay-index-tsx" */),
  "component---src-pages-signup-pay-pay-error-tsx": () => import("./../../../src/pages/signup/pay/payError.tsx" /* webpackChunkName: "component---src-pages-signup-pay-pay-error-tsx" */),
  "component---src-pages-signup-pay-right-col-right-panel-error-tsx": () => import("./../../../src/pages/signup/pay/_rightCol/rightPanelError.tsx" /* webpackChunkName: "component---src-pages-signup-pay-right-col-right-panel-error-tsx" */),
  "component---src-pages-signup-pay-right-col-right-panel-progress-tsx": () => import("./../../../src/pages/signup/pay/_rightCol/rightPanelProgress.tsx" /* webpackChunkName: "component---src-pages-signup-pay-right-col-right-panel-progress-tsx" */),
  "component---src-pages-signup-success-js": () => import("./../../../src/pages/signup/success.js" /* webpackChunkName: "component---src-pages-signup-success-js" */),
  "component---src-pages-ylabel-index-tsx": () => import("./../../../src/pages/ylabel/index.tsx" /* webpackChunkName: "component---src-pages-ylabel-index-tsx" */),
  "component---src-templates-blog-template-tsx": () => import("./../../../src/templates/blogTemplate.tsx" /* webpackChunkName: "component---src-templates-blog-template-tsx" */)
}

